<template>
<div class="template-full-width">
 



<div class="faq-content">
  <CgvMenuComponent :appView="appView" :language="language" page="policy" />

    <div v-if="language=='fr'">
      <div class="faq-category">
        <div class="category-title">Préambule</div>
        <div class="category-content">
        <p>Cette politique de confidentialité s'applique quels que soient nos magasins, sites, applications ou fonctionnalités de site Web que vous utilisez, ainsi que toute application mobile qui comprend un lien autorisé vers cette politique de confidentialité (magasins, sites Web et applications ici appelés les «Services»). Votre utilisation de nos services est également soumise à nos conditions d'utilisation.
      <br />En utilisant nos services, vous acceptez la collecte, l'utilisation, la divulgation et les procédures décrites par cette politique de confidentialité. Veuillez noter que cette politique de confidentialité ne s'applique pas aux sites Web tiers («Services tiers»), tels que Facebook, Twitter, Pinterest, Instagram, Snapchat et Google+, que nous ne possédons ni ne contrôlons, même si vous y accédez via nos services. Nous essayons de travailler uniquement avec des services tiers qui partagent notre respect pour votre vie privée, mais nous ne pouvons pas assumer la responsabilité de leurs pratiques, contenu ou politiques de confidentialité. Nous vous encourageons à lire attentivement les politiques de confidentialité de tous les services tiers auxquels vous accédez afin que vous sachiez comment ils collecteront, utiliseront et partageront vos informations.
      <br />Si vous avez des questions, veuillez nous contacter à <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
        </div>
      </div>
      <div class="faq-category">
      <div class="category-title">INFORMATIONS PERSONNELLEMENT IDENTIFIABLES</div>
      <div class="category-content">
        <p>Nous ne partagerons jamais votre adresse e-mail ou vos informations personnelles pour un usage externe. Toutes les informations collectées sur ce site Web seront utilisées à des fins de contrôle de la fraude, de vérification de commande ou de marketing interne uniquement. Nous pouvons utiliser des services tiers pour aider à gérer la communication, mais les conditions contractuelles avec ces services interdisent absolument leur utilisation ou la distribution des informations que nous stockons sur leurs serveurs, et ces services utilisent un cryptage de haut niveau pour protéger vos données. À tout moment, vous pourrez supprimer votre e-mail ou d'autres informations de contact de nos systèmes en nous envoyant un e-mail à <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
      </div>
      </div>
      <div class="faq-category">
        <div class="category-title">SCAN VISAGE ET NUMÉRISATION 3D</div>
        <div class="category-content">
        <p>Les clients qui achètent des lunettes personnalisées via l'application mobile ou le site web SCAN TO WEAR sont également scannés lors du processus d'achat à l'aide d'un iPhone ou d'un iPad. Au cours de ce processus, la topologie faciale et les informations sur le visage sont capturées au format 3D (liste de coordonées), puis envoyées aux serveurs de SCAN TO WEAR.
          <br />Les photos et données numériques enregistrées dans les flux de capture interactifs de l'application utilisés pour créer un modèle 3D de votre visage (le «selfie» le «Scan» ou encore «les données numériques») sont privées et ne sont pas considérées comme du «contenu utilisateur» tel que défini ailleurs dans le présent politique.  Pendant le processus de numérisation, les données ne sont pas partagées avec Apple ou d'autres tiers, sauf si vous nous en faites explicitement la demande. Nous n'utiliserons vos scans 3D et vos données numériques que pour répondre aux commandes ou demandes de support technique. Les données biométriques sont temporairement stockées sur le périphérique local, puis envoyées directement aux serveurs de l'entreprise SCAN TO WEAR. Les scans sont stockés de manière cryptée sur les serveurs et seront uniquement accessibles pour un traitement de commande ou une personnalisation produit ultérieur. Le client ou l'opticien peut supprimer ses scans directement sur le site web privé (portail) ou via l'application mobile, ou demander à l'équipe SCAN TO WEAR de les supprimer. À cet effet, veuillez contacter <a href="mailto:hello@visages.com">hello@visages.com</a>. Si nous supprimons votre scan à votre demande, nous ne pourrons pas finaliser une commande sans que vous ne téléchargiez ultérieurement un nouveau scan.
        </p>
        </div>
      </div>
      <div class="faq-category">
      <div class="category-title">API TRUEDEPTH </div>
      <div class="category-content">
      <p>Le Service utilise également les informations collectées automatiquement à l'aide de la caméra de l'appareil et de l'API TrueDepth fournie par Apple.Sont ainsi récoltés une liste de coordonnées 3D du visage ainsi que ses pigments de couleurs, dans le but de modéliser un avatar ou une représentation photo-réaliste du visage de l’utilisateur, avec pour unique objectif d’utilisation de ces informations, de produire une lunette personnalisée et parfaitement ajustée aux besoins morphologiques et optiques de l’utilisateur. Les données du modèle de visage sont stockées sur des serveurs protégés par mots de passe hébergés par Google et/ou Amazon, dont les deux services Web sont considérés comme les plus sûrs pour stocker et transmettre des données privées de santé et financières.</p>
      </div>
      </div>
      <div class="faq-category">
      <div class="category-title">CONVERSATIONS PAR E-MAIL ET DISCUSSIONS AVEC LE SERVICE CLIENT</div>
      <div class="category-content">
      <p>Des communications directes existent dans le but d'éduquer et d'informer les utilisateurs sur nos produits, de les aider à faire des sélections ainsi que de discuter de leurs commandes. En tant que tel, toute information ou image partagée par e-mail avec les boîtes de réception de notre service client, partagée via la fonction de chat de notre application ou qui nous est envoyée via un message direct sur Facebook ou Twitter n'est pas considérée comme un contenu utilisateur tel que décrit ci-dessous. Ces informations ne seront pas partagées en externe sans l'autorisation explicite de l'utilisateur. Elles peuvent être partagées en interne au sein de l'entreprise si nécessaire pour former nos collaborateurs ou pour répondre à la demande d'un client.</p>
      </div>
      </div>
      
      <div class="faq-category">
      <div class="category-title">COOKIES DE SITE WEB</div>
      <div class="category-content">
      <p>Notre site Web utilise des cookies afin d'assurer une expérience cohérente pour tout utilisateur individuel sur tous les points de contact avec SCAN TO WEAR. L'utilisation d'une fonctionnalité de navigateur qui bloque les cookies ne restreindra pas l'accès à notre site Web, même si cela peut conduire à des performances moins optimales.</p>
      </div>
      </div>
      <div class="faq-category">
      <div class="category-title">CONTENU UTILISATEUR</div>
      <div class="category-content">
      <p>Toutes les informations, photographies et vidéos non mentionnées ci-dessus qui sont transmises ou publiées sur nos Services, sur l'application, le site Web SCAN TO WEAR ou sur des plateformes tiers (tels que Facebook, YouTube, Twitter, etc.) relèvent de notre politique de contenu utilisateur décrite dans nos conditions de service. Les exemples incluent, mais sans s'y limiter, les réponses aux sondages et les critiques de l'App Store iOS. Les utilisateurs ne transfèrent pas la propriété de leur contenu utilisateur, mais ils garantissent que le contenu leur appartient, qu'il est approprié pour le service, et ils accordent une licence à SCAN TO WEAR et à d'autres utilisateurs des services de SCAN TO WEAR pour le distribuer et l'utiliser comme souhaité. Pour une déclaration plus complète de la politique relative au contenu utilisateur, veuillez consulter la page Conditions d'utilisation ou envoyer vos questions à <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
      </div>
      </div>

      <div class="faq-category">
      <div class="category-title">INFORMATIONS SUR LES ENFANTS</div>
      <div class="category-content">
      <p>Nous sommes particulièrement sensibles aux informations des enfants. Nous ne collectons pas sciemment d'informations personnelles d'enfants de moins de 13 ans, conformément à la loi sur la protection de la vie privée en ligne des enfants («COPPA»). Si vous êtes le parent ou le tuteur légal d'un enfant mineur, vous pouvez utiliser nos Services au nom de cet enfant mineur. Si vous avez des questions concernant nos pratiques d'information concernant les enfants, ou si vous pensez qu'un enfant de moins de 13 ans nous a fourni des informations personnelles, veuillez nous envoyer un e-mail à <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
      </div>
      </div>

      <div class="faq-category">
        <div class="category-title">MODIFICATIONS DE CETTE POLITIQUE DE CONFIDENTIALITÉ</div>
        <div class="category-content">
        <p>Nous révisons occasionnellement cette politique de confidentialité pour nous assurer qu'elle est conforme aux lois applicables et conforme aux changements dans notre entreprise. Nous pouvons avoir besoin de mettre à jour cette politique de confidentialité, et nous nous réservons le droit de le faire à tout moment. Votre utilisation continue de nos services constitue votre acceptation des termes de la politique de confidentialité telle que modifiée ou révisée par nous de temps à autre.</p>
        </div>
      </div>

      <div class="faq-category">
        <div class="category-title">NOUS CONTACTER</div>
        <div class="category-content">
        <p>Si vous avez des questions sur l'affichage ou la mise à jour de vos informations, cette politique de confidentialité ou nos pratiques de confidentialité, veuillez nous contacter à <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
        </div>
      </div>

    </div>

    <div v-else>
      <div class="faq-category">
        <div class="category-title">Preamble</div>
        <div class="category-content">
        <p>This privacy policy applies to all our stores, websites, applications, or website features you use, as well as any mobile application that includes an authorized link to this privacy policy (referred to as "Services" here). Your use of our services is also subject to our terms of use.
          <br />By using our services, you agree to the collection, use, disclosure, and procedures described in this privacy policy. Please note that this privacy policy does not apply to third-party websites ("Third-Party Services"), such as Facebook, Twitter, Pinterest, Instagram, Snapchat, and Google+, that we do not own or control, even if you access them through our services. We strive to work only with third-party services that share our respect for your privacy, but we cannot assume responsibility for their practices, content, or privacy policies. We encourage you to carefully read the privacy policies of all third-party services you access so that you understand how they will collect, use, and share your information.
          <br />If you have any questions, please contact us at <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
        </div>
      </div>
      <div class="faq-category">
      <div class="category-title">PERSONALLY IDENTIFIABLE INFORMATION</div>
      <div class="category-content">
        <p>We will never share your email address or personal information for external use. All information collected on this website will be used for fraud control, order verification, or internal marketing purposes only. We may use third-party services to help manage communication, but the contractual terms with these services strictly prohibit their use or distribution of the information we store on their servers, and these services use high-level encryption to protect your data. At any time, you can remove your email or other contact information from our systems by sending us an email at <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
      </div>
      </div>
      <div class="faq-category">
        <div class="category-title">FACE SCANNING AND 3D SCANNING</div>
        <div class="category-content">
        <p>Customers who purchase custom glasses from the SCAN TO WEAR mobile application or website are also scanned during the purchase process using an iPhone or an iPad. During this process, the facial topology and information about the face are captured in 3D format (list of coordinates), and sent to SCAN TO WEAR's servers.
          <br />The photos and digital data recorded in the interactive capture streams of the application used to create a 3D model of your face (referred to as "selfie," "Scan," or "digital data") are private and not considered "user-generated content" as defined elsewhere in this policy. During the scanning process, the data is not shared with Apple or other third parties unless you explicitly request us to do so. We will only use your 3D scans and digital data to respond to product ordering or technical support requests. The biometric data is only temporarily stored on the local device and then sent directly to the servers of the company SCAN TO WEAR. There, these scans are available for further processing or customization of the glasses. The scans are stored and encrypted on the servers – there is no possibility of direct access to this data from the outside.
          <br />The customer or optician can delete their scans directly themselves on the private website (portal) or directly through the mobile application or arrange for the SCAN TO WEAR team to delete them. For this purpose, please contact <a href="mailto:hello@visages.com">hello@visages.com</a>. If we delete your scan at your request, we will not be able to complete an order without you subsequently uploading another scan.
        </p>
        </div>
      </div>
      <div class="faq-category">
      <div class="category-title">TRUEDEPTH API</div>
      <div class="category-content">
      <p>The Service also utilizes information automatically collected using the device's camera and the TrueDepth API provided by Apple. This includes gathering a list of 3D facial coordinates and color pigments in order to model a meshed avatar or photo-realistic representation of the user's face, with the sole purpose of producing a personalized and perfectly fitted eyewear product based on the user's morphological and optical needs. The face model data is stored encrypted on password-protected servers hosted by Google and/or Amazon, both of which are considered sufficiently secure for storing and transmitting private health record data and financial data.</p>
      </div>
      </div>
      <div class="faq-category">
      <div class="category-title">EMAIL CONVERSATIONS AND CUSTOMER SERVICE DISCUSSIONS</div>
      <div class="category-content">
      <p>Direct communications exist for the purpose of educating and informing users about our products, assisting them in making selections, as well as discussing their orders. As such, any information or image shared via email with our customer service inboxes, shared through the chat function of our application, or sent to us via a direct message on Facebook or Twitter is not considered user-generated content as described below. This information will not be shared externally without the explicit permission of the user. It may be shared internally within the company if necessary to train our employees or to respond to a customer's request.</p>
      </div>
      </div>
      <div class="faq-category">
      <div class="category-title">CHILDREN'S INFORMATION</div>
      <div class="category-content">
      <p>We are particularly sensitive to children's information. We do not knowingly collect personal information from children under the age of 13, in accordance with the Children's Online Privacy Protection Act ("COPPA"). If you are the parent or legal guardian of a minor child, you may use our Services on behalf of that minor child. If you have any questions regarding our information practices concerning children or if you believe that a child under 13 has provided us with personal information, please email us at <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
      </div>
      </div>
      <div class="faq-category">
      <div class="category-title">WEBSITE COOKIES</div>
      <div class="category-content">
      <p>Our website uses cookies to ensure a consistent experience for each individual user across all touchpoints with SCAN TO WEAR. Using a browser feature that blocks cookies will not restrict access to our website, although it may lead to less optimal performance.</p>
      </div>
      </div>
      <div class="faq-category">
      <div class="category-title">USER-GENERATED CONTENT</div>
      <div class="category-content">
      <p>Any information, photographs, and videos not mentioned above that are transmitted or posted on our Services, the SCAN TO WEAR application, the SCAN TO WEAR website, or on third-party platforms (such as Facebook, YouTube, Twitter, etc.) fall under our user-generated content policy described in our terms of service. Examples include, but are not limited to, survey responses and reviews on the iOS App Store. Users do not transfer ownership of their user-generated content, but they warrant that the content belongs to them, is appropriate for the service, and grant a license to SCAN TO WEAR and other SCAN TO WEAR service users to distribute and use it as desired. For a more comprehensive statement of the user-generated content policy, please refer to the Terms of Use page or send your questions to <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
      </div>
      </div>

      

      <div class="faq-category">
        <div class="category-title">CHANGES TO THIS PRIVACY POLICY</div>
        <div class="category-content">
        <p>We occasionally review this privacy policy to ensure compliance with applicable laws and changes in our business. We may need to update this privacy policy, and we reserve the right to do so at any time. Your continued use of our services constitutes your acceptance of the terms of the privacy policy as modified or revised by us from time to time.</p>
        </div>
      </div>

      <div class="faq-category">
        <div class="category-title">CONTACT US</div>
        <div class="category-content">
        <p>If you have any questions about viewing or updating your information, this privacy policy, or our privacy practices, please contact us at <a href="mailto:hello@visages.com">hello@visages.com</a>.</p>
        </div>
      </div>

    </div>

    </div>

		</div>
    <br /><br /><br /><br />
    <Footer v-if="appView==false" :language="language" :displayModalMenu="displayModalMenu" />
 
  
</template>

<script>

import Footer from '../components/layout/Footer.vue'
import jQuery  from 'jquery';

import CgvMenuComponent from "../components/children/CGV/CgvMenuComponent.vue" 
import { useMeta } from 'vue-meta'

export default {
  name: 'Confidentiality',
  components: {
    Footer,
    CgvMenuComponent
  },
  data() {
    return {
      controller: null,
      tween: null,
      scene: null
    }

    },
    props:["displayModalMenu","language","appView"],
    watch:{
        
        language:  function () {
          setTimeout(function(){
            jQuery('.faq-category .category-title').click(function(){
              
              if(jQuery(this).parent().find('.category-content').is(':visible'))
              {
                  jQuery(this).parent().find('.category-content').slideUp();
                  jQuery(this).removeClass('open');
              }
              else
              {
                  jQuery(this).parent().find('.category-content').slideDown();
                  jQuery(this).addClass('open');
              }
              
          });
          
          jQuery('.faq-category .question').click(function(){
              
              if(jQuery(this).next('.answer').is(':visible'))
              {
                  jQuery(this).next('.answer').slideUp();
                  jQuery(this).removeClass('open');
              }
              else
              {
                  jQuery(this).addClass('open');
                  jQuery(this).next('.answer').slideDown();
              }
              
          });
          },100)
           
        },
      },


      created() {


        if(this.language=="fr")
    {

      useMeta({
    title: "VISAGES - Politique de confidentialité",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})

document.querySelector('meta[name="description"]').setAttribute('content',"Politique de confidentialité VISAGES.")
    }
    else
    {

      useMeta({
    title: "VISAGES - Private policy",
    
    htmlAttrs: { lang: this.language, amp: true },
    
})
    
document.querySelector('meta[name="description"]').setAttribute('content',"VISAGES Private policy.")
      
    }



},
  mounted()
  {
     jQuery(document).ready(function(){
								
        jQuery('.faq-category .category-title').click(function(){
            
            if(jQuery(this).parent().find('.category-content').is(':visible'))
            {
                jQuery(this).parent().find('.category-content').slideUp();
                jQuery(this).removeClass('open');
            }
            else
            {
                jQuery(this).parent().find('.category-content').slideDown();
                jQuery(this).addClass('open');
            }
            
        });
        
        jQuery('.faq-category .question').click(function(){
            
            if(jQuery(this).next('.answer').is(':visible'))
            {
                jQuery(this).next('.answer').slideUp();
                jQuery(this).removeClass('open');
            }
            else
            {
                jQuery(this).addClass('open');
                jQuery(this).next('.answer').slideDown();
            }
            
        });
        
    });
  }
  
}
</script>
<style scoped>
@media screen and (max-width:760px)
{
  body .faq-category:nth-child(2) .category-title
{
  border-top:0 !important;
}
  body .faq-category .category-title::after
  {

    top:50% !important;
    transform: translateY(-50%) !important;
  }
.category-content {
  padding-left:0px !important;
}
  body .faq-category .category-title {
    font-size: 14px !important;
    padding-left:50px !important;
    padding-top:20px !important;
    padding-bottom:20px !important;
  }
  body .faq-category .category-title::before {
    width:35px !important;
    height:35px !important;
    top : 12px !important;
  }
}
.category-content {
  padding-left:80px;
}
body .faq-category .category-title.open::after
{
  content:"-";
}
body .faq-category .category-title::after
{
	position:absolute;
	top:35px;
  font-weight: 400 !important;
	transition:none;
  font-size:18px;
	right:10px;
	content:'+';
	transform: none;
}
body .faq-category .category-title.open::before
{
  
  background-image:url("../../public/assets/images/arrow2.png");
}
body .faq-category .category-title::before
{
  content:" ";
  position:absolute;
  left:0;
  top:17px;
  width:56px;
  height:56px;
  background-size:100% 100%;
  background-image:url("../../public/assets/images/arrow5.png");
}
body .faq-category .category-title.open {
  font-weight: 600;
}
body .faq-category:nth-child(2) .category-title
{
  border-top:1px solid #ccc;
}
body .faq-category .category-title
{
	position:relative;
  padding-left:80px;
	cursor:pointer;
  line-height: 1;
	font-weight:400;
	padding-bottom:35px;
	padding-top:35px;
  text-transform: none;
  font-size:18px;
	border-bottom:1px solid #ccc;
}

body .faq-content
{
	padding-left:15%;
	padding-right:15%;
	padding-top:50px;
	padding-bottom:50px;
	min-height:286px;
	display:block;
	vertical-align:top;
	width:100% !important;
	
}
.faq-content
{
	margin-top:50px !important;
}
a 
{
color:#000;
}
</style>